import React from "react";
import PromoList from "@/components/PromoList";

const PromoList4 = () => {
  return (
    <div>
      <PromoList cardNumer={4}/>
    </div>
  );
};

export default PromoList4;
